import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import { getAgenasDisciplineUrl } from '../../core/router/routes-provider';
import { AgenasDisciplineType } from './agenas-discipline.model';

@Injectable({ providedIn: 'root' })
export class AgenasDisciplineService {
  constructor(private callService: CallService, private logService: LogService) { }

  loadAgenasDiscipline(): Observable<AgenasDisciplineType[]> {
    const urltoCall = getAgenasDisciplineUrl();
    this.logService.info('Calling ', urltoCall);
    const result = this.callService.CallApi<AgenasDisciplineType[]>('GET', urltoCall);
    return result;
  }
}
