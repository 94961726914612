<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center desktop-dialog">
  <div class="flex-row flex-justify-space-between flex-align-center">
    <img id="dialogLogoDN" (click)="onExitLog()" [src]="infoLogoDn" [alt]="'logo'" />
    <div class="dialog-title">{{ getTitle() }}</div>
  </div>
  @if (!disableClose) {
  <button mat-icon-button (click)="onExitLog()">
    <fa-icon class="dn-icon" [icon]="getCrossIcon()"></fa-icon>
  </button> }
</div>
<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center mobile-dialog">
  @if (!disableClose) {
  <button mat-icon-button (click)="onExitLog()">
    <fa-icon class="dn-icon" [icon]="getChevronLeftIcon()"></fa-icon>
  </button> }
  <div class="dialog-title">{{ getTitle() }}</div>
  @if (!disableClose) {
  <img id="dialogLogoDN" (click)="onExitLog()" [src]="infoLogoDn" [alt]="'logo'" /> }
</div>

<hr class="dialog-title-separator" />

<mat-dialog-content>
  <p [innerHTML]="getWallBody()"></p>
  @if (isSubmitting) {
  <dottnet-spinner></dottnet-spinner>
  }
  <dottnet-user-form [ngStyle]="{ 'pointer-events': isSubmitting ? 'none' : 'auto' }" [user]="user$ | async"
    [categoryOptions$]="categories$" [specialtyOptions$]="specialties$" [professionTypesOptions$]="professionTypes$"
    [agenasDisciplineTypesOptions$]="agenasDisciplineTypes$" [cityOptions$]="cities$" [provinceOptions$]="provinces$"
    [listaGruppi]="listaGruppi" [showEcm]="showEcm" [ecmChecked]="ecmChecked" [showPersonal]="showPersonal"
    [showProfessional]="showProfessional" [showConsents]="showConsents" [showEmail]="showEmail" [showPwd]="showPwd"
    [formType]="UserFormType.EDIT" [isSubmitting]="isSubmitting" [requestForm$]="requestForm$"
    (lookupCities)="lookupCities($event)" (submitForm)="submitForm($event)" (sendForm)="sendForm($event)">
  </dottnet-user-form>
</mat-dialog-content>