import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { onlyWhenAuthenticated, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { loadAgenasDiscipline, loadAgenasDisciplineFailure, loadAgenasDisciplineSuccess } from './agenas-discipline.actions';
import { AgenasDisciplineType } from './agenas-discipline.model';
import { selectAgenasDiscipline } from './agenas-discipline.selectors';
import { AgenasDisciplineService } from './agenas-discipline.service';

@Injectable({
  providedIn: 'root'
})
export class AgenasDisciplineEffects {
  loadAgenasDiscipline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAgenasDiscipline),
      onlyWhenOnline(this.store),
      onlyWhenAuthenticated(this.store),
      tap((action) => this.logService.info('Effect: loadAgenasDiscipline', action)),
      concatLatestFrom(() => this.store.select(selectAgenasDiscipline)),
      filter(([_, agenasdisciplinetypes]) => agenasdisciplinetypes.length === 0),
      switchMap(() =>
        this.agenasDisciplineService.loadAgenasDiscipline().pipe(
          tap((datiAgenasDiscipline: AgenasDisciplineType[]) =>
            this.logService.infoDebug('Effect: loadAgenasDiscipline data received --> ', datiAgenasDiscipline)
          ),
          map((datiAgenasDiscipline: AgenasDisciplineType[]) => loadAgenasDisciplineSuccess({ datiAgenasDiscipline })),
          catchError((error: ErrorMessage) => of(loadAgenasDisciplineFailure({ error })))
        )
      )
    )
  );

  //  load failure
  loadAgenasDisciplineFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAgenasDisciplineFailure),
      concatLatestFrom(() => this.store.select(selectAuthLoginState)),
      tap((action) => this.logService.info('Effect: loadAgenasDisciplineFailure ', action)),
      map(([{ error }, authLoginState]) => manageError(error, authLoginState))
    )
  );

  constructor(
    private actions$: Actions,
    private agenasDisciplineService: AgenasDisciplineService,
    private logService: LogService,
    private store: Store
  ) { }
}
