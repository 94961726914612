/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, filter } from 'rxjs';
import { saveClickLog } from '../../../core/clicks/click.actions';
import { ClickLog, ClickOperation } from '../../../core/clicks/click.models';
import { Category } from '../../../domain/category/category.model';
import { selectCategory } from '../../../domain/category/category.selectors';
import { loadCityByPrefix } from '../../../domain/city/city.actions';
import { City } from '../../../domain/city/city.model';
import { selectCityByPrefix } from '../../../domain/city/city.selectors';
import { Province } from '../../../domain/province/province.model';
import { selectProvince } from '../../../domain/province/province.selectors';
import { Specialty } from '../../../domain/specialty/specialty.model';
import { selectSpecialty } from '../../../domain/specialty/specialty.selectors';
import { updateUser } from '../../../domain/user/user.actions';
import { ListaGruppiEnum, User, UserDTO, ListaGruppi } from '../../../domain/user/user.model';
import { selectUser, selectUserOperationState } from '../../../domain/user/user.selectors';
import { loadDropDownData } from '../../user-form/user-form.actions';
import { UserForm, UserFormType } from '../../user-form/user-form.model';
import { DNOperationState, evaluateBooleanFromString, getDateInUTC } from '../../util/util';
import { DottnetDialogComponent } from '../dn-dialog.component';
import { UserFormComponent } from '../../user-form/user-form.component';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { NgStyle, AsyncPipe } from '@angular/common';
import { ProfessionType } from '../../../domain/profession/profession.model';
import { selectProfession } from '../../../domain/profession/profession.selectors';
import { AgenasDisciplineType } from 'src/app/domain/agenas-discipline/agenas-discipline.model';
import { selectAgenasDiscipline } from 'src/app/domain/agenas-discipline/agenas-discipline.selectors';

export interface WallDialogData {
  listaGruppi: ListaGruppi[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dottnet-mat-dialog-wall',
  templateUrl: 'wall-dialog.component.html',
  styleUrls: ['wall-dialog.component.scss'],
  imports: [MatDialogModule, MatButtonModule, FontAwesomeModule, SpinnerComponent, UserFormComponent, NgStyle, AsyncPipe]
})
export class WallDialogComponent
  extends DottnetDialogComponent<WallDialogComponent>
  implements OnInit, OnDestroy {
  readonly UserFormType = UserFormType;
  // Options for autocompletes
  categories$: Observable<Category[]> = new Observable();
  specialties$: Observable<Specialty[]> = new Observable();
  provinces$: Observable<Province[]> = new Observable();
  cities$: Observable<City[]> = new Observable();
  professionTypes$: Observable<ProfessionType[]> = new Observable();
  agenasDisciplineTypes$: Observable<AgenasDisciplineType[]> = new Observable();

  // User settings
  user$: Observable<UserDTO> = new Observable();

  // ListaGruppi
  listaGruppi: ListaGruppiEnum[] = [];

  // User vars
  password: string = '';
  idAnagrafica: number = -1;
  privacy: boolean = false;
  confermaMail: boolean = false;
  isSubmitting: boolean = false;

  // Subs
  userSub: Subscription = new Subscription();
  userOperationSub: Subscription = new Subscription();
  requestForm$: Subject<boolean> = new Subject<boolean>();

  showPersonal: boolean = false;
  showProfessional: boolean = false;
  showConsents: boolean = false;
  showEmail: boolean = false;
  showPwd: boolean = false;
  showEcm: boolean = false;
  ecmChecked: boolean = false;

  constructor(
    dialogRef: MatDialogRef<WallDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private wallData: WallDialogData,
    @Inject(PLATFORM_ID) platformId: Object,
    public store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private ts: TranslateService
  ) {
    super(dialogRef, undefined, store, platformId);
  }

  ngOnInit(): void {
    if (this.wallData.listaGruppi.find((elem) => elem.mandatory)) {
      this.dialogRef.disableClose = true;
    }

    this.listaGruppi = this.getListaGruppiFromArray(this.wallData.listaGruppi);

    if (this.listaGruppi.includes(ListaGruppiEnum.Professional) || this.listaGruppi.includes(ListaGruppiEnum.ECM)) {
      this.store.dispatch(loadDropDownData());
    }

    this.categories$ = this.store.select(selectCategory);
    this.specialties$ = this.store.select(selectSpecialty);
    this.professionTypes$ = this.store.select(selectProfession);
    this.agenasDisciplineTypes$ = this.store.select(selectAgenasDiscipline);
    this.provinces$ = this.store.select(selectProvince);
    this.user$ = this.store.select(selectUser);

    this.userSub = this.user$.pipe(filter((utente: UserDTO) => !!utente)).subscribe((utente) => {
      this.password = utente.password;
      this.idAnagrafica = utente.idAnagrafica;
      this.privacy = utente.privacy;
      this.confermaMail = utente.confermaMail;

      this.listaGruppi?.map((elem) => {
        switch (elem) {
          case ListaGruppiEnum.Personal:
            this.showPersonal = true;
            this.showEmail = true;
            if (this.password && this.password.length === 0) {
              this.showPwd = true;
              // this.dialogRef.disableClose = true;   // potrei disabilitare la chiusura della dialog se mi manca la pwd. Ma per ora non lo facciamo
            }
            break;
          case ListaGruppiEnum.Professional:
            this.showProfessional = true;
            break;
          case ListaGruppiEnum.Consents:
            this.showConsents = true;
            break;
          case ListaGruppiEnum.ECM:
            this.showEcm = true;
            this.ecmChecked = true;
            break;
        }
      });
    });

    this.userOperationSub = this.store.select(selectUserOperationState).subscribe((userOp) => {
      if (DNOperationState.LOADING === userOp) {
        this.isSubmitting = true;
      } else {
        this.isSubmitting = false;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.userSub) this.userSub.unsubscribe();
    if (this.userOperationSub) this.userOperationSub.unsubscribe();
  }

  getTitle(): string {
    return this.ts.instant('dottnet.wall.title');
  }

  // TODO move this to translation files
  getWallBody(): string {
    return this.listaGruppi?.includes(ListaGruppiEnum.Consents)
      ? this.ts.instant('dottnet.wall.bodyConsent')
      : this.ts.instant('dottnet.wall.bodyGeneral');
  }

  lookupCities(prefix: string) {
    this.store.dispatch(loadCityByPrefix({ prefix }));
    this.cities$ = this.store.select(selectCityByPrefix);
  }

  submitForm(form: FormGroup<UserForm>) {
    if (form.valid) {
      const { personalInfos, professionalInfos, consentsForm, ecmInfos } = form.getRawValue();

      const userToUpdate: User = {
        email: personalInfos.email,
        password: this.showPwd ? personalInfos.password : this.password,
        nome: personalInfos.name,
        cognome: personalInfos.surname,
        sesso: personalInfos.gender,
        codiceFiscale: personalInfos.codiceFiscale,
        idAnagrafica: this.idAnagrafica,
        idCategoria: professionalInfos.category.idCategoria,
        idSpecializzazione: professionalInfos.specialty.idSpecializzazione,
        idComuneNascita: personalInfos.birthplace?.idComune,
        dataNascita: getDateInUTC(new Date(personalInfos.birthdate)),
        privacy: this.privacy,
        marketing: evaluateBooleanFromString(consentsForm.marketing),
        profilazioneTerzi: evaluateBooleanFromString(consentsForm.profilingThirdParties),
        marketingTerzi: evaluateBooleanFromString(consentsForm.marketingThirdParties),
        profilazione: evaluateBooleanFromString(consentsForm.profiling),
        numeroIscrizioneAlbo: professionalInfos.registerForm.registerNumber,
        provinciaIscrizioneAlbo: professionalInfos.registerForm.registerProvince.siglaProvincia,
        ipAddress: '',
        confermaMail: this.confermaMail,
        cellulare: ecmInfos.cellulare,
        idTipoProfessione: ecmInfos.professionType.idTipoProfessione,
        cap: ecmInfos.cap,
        idComuneEsercizio: ecmInfos.professionPlace.idComune,
        idDisciplinaAgenas: ecmInfos.agenasDiscipline.idDisciplinaAgenas
      };

      this.store.dispatch(updateUser({ newUser: userToUpdate }));

      // TODO: clickLog success
      const clickLog: ClickLog = <ClickLog>{};
      clickLog.clickLogOperationID = ClickOperation.ModificaProfilo;
      clickLog.content = JSON.stringify(userToUpdate);
      clickLog.message = 'Wall aggiornato';
      clickLog.method = 'POST';
      clickLog.outcome = true;
      this.store.dispatch(saveClickLog({ clickLog }));
      this.dialogRef.disableClose = false;
      super.onExit();
    } else {
      form.markAllAsTouched();
    }
  }

  sendForm(form: FormGroup<UserForm>): void {
    // TODO: clickLog Form close without saving
    const { personalInfos, professionalInfos, consentsForm, ecmInfos } = form.getRawValue();
    const objectToLog: User = <User>{};

    if (this.showPersonal) {
      (objectToLog.idAnagrafica = this.idAnagrafica),
        (objectToLog.email = personalInfos.email),
        (objectToLog.nome = personalInfos.name ? personalInfos.name : null),
        (objectToLog.cognome = personalInfos.surname ? personalInfos.surname : null),
        (objectToLog.sesso = personalInfos.gender ? personalInfos.gender : null),
        (objectToLog.codiceFiscale = personalInfos.codiceFiscale
          ? personalInfos.codiceFiscale
          : null),
        (objectToLog.idComuneNascita = personalInfos.birthplace.idComune
          ? personalInfos.birthplace.idComune
          : null),
        (objectToLog.dataNascita = getDateInUTC(new Date(personalInfos.birthdate))
          ? getDateInUTC(new Date(personalInfos.birthdate))
          : null);
    }

    if (this.showProfessional) {
      (objectToLog.idAnagrafica = this.idAnagrafica),
        (objectToLog.email = personalInfos.email),
        (objectToLog.idCategoria = professionalInfos.category.idCategoria
          ? professionalInfos.category.idCategoria
          : null),
        (objectToLog.idSpecializzazione = professionalInfos.specialty.idSpecializzazione
          ? professionalInfos.specialty.idSpecializzazione
          : null),
        (objectToLog.numeroIscrizioneAlbo = professionalInfos.registerForm.registerNumber
          ? professionalInfos.registerForm.registerNumber
          : null),
        (objectToLog.provinciaIscrizioneAlbo = professionalInfos.registerForm.registerProvince
          .siglaProvincia
          ? professionalInfos.registerForm.registerProvince.siglaProvincia
          : null);
    }

    if (this.showConsents) {
      (objectToLog.idAnagrafica = this.idAnagrafica),
        (objectToLog.email = personalInfos.email),
        (objectToLog.marketing = evaluateBooleanFromString(consentsForm.marketing)),
        (objectToLog.profilazioneTerzi = evaluateBooleanFromString(
          consentsForm.profilingThirdParties
        )),
        (objectToLog.marketingTerzi = evaluateBooleanFromString(
          consentsForm.marketingThirdParties
        )),
        (objectToLog.profilazione = evaluateBooleanFromString(consentsForm.profiling));
    }

    if (this.showEcm) {
      (objectToLog.idAnagrafica = this.idAnagrafica),
        (objectToLog.email = personalInfos.email),
        (objectToLog.cellulare = ecmInfos.cellulare ? ecmInfos.cellulare : null),
        (objectToLog.cap = ecmInfos.cap ? ecmInfos.cap : null),
        (objectToLog.idComuneEsercizio = ecmInfos.professionPlace.idComune
          ? ecmInfos.professionPlace.idComune
          : null),
        (objectToLog.idTipoProfessione = ecmInfos.professionType.idTipoProfessione
          ? ecmInfos.professionType.idTipoProfessione
          : null);
    }

    const clickLog: ClickLog = <ClickLog>{};
    clickLog.clickLogOperationID = ClickOperation.ChiusuraFormCompletaProfilo;
    clickLog.content = JSON.stringify(objectToLog);
    clickLog.message = 'Wall abbandonato';
    clickLog.method = 'CLICK';
    clickLog.outcome = false;
    this.store.dispatch(saveClickLog({ clickLog }));
  }

  onExitLog() {
    // TODO: clickLog Form close without saving
    this.requestForm$.next(true);
    super.onExit();
  }

  getListaGruppiFromArray(listaGruppi: ListaGruppi[]): ListaGruppiEnum[] {
    const resultList: ListaGruppiEnum[] = [];

    if (
      listaGruppi.filter(function (elem) {
        return elem.group === 'anagrafica';
      }).length > 0
    )
      resultList.push(ListaGruppiEnum.Personal);
    if (
      listaGruppi.filter(function (elem) {
        return elem.group === 'username_password';
      }).length > 0
    )
      resultList.push(ListaGruppiEnum.Personal);
    if (
      listaGruppi.filter(function (elem) {
        return elem.group === 'dati_professionali';
      }).length > 0
    )
      resultList.push(ListaGruppiEnum.Professional);
    if (
      listaGruppi.filter(function (elem) {
        return elem.group === 'consensi';
      }).length > 0
    )
      resultList.push(ListaGruppiEnum.Consents);
    if (
      listaGruppi.filter(function (elem) {
        return elem.group === 'ecm';
      }).length > 0
    )
      resultList.push(ListaGruppiEnum.ECM);

    return resultList;
  }
}
