import {
  APP_INITIALIZER,
  PLATFORM_ID,
  importProvidersFrom,
  Injectable,
  ErrorHandler,
  Injector,
  isDevMode,
  ApplicationConfig,
  TransferState,
  Optional,
  InjectionToken,
  provideZoneChangeDetection,
  provideAppInitializer,
  inject
} from '@angular/core';

import { environment } from '../../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  InMemoryScrollingOptions,
  NoPreloading,
  ParamMap,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading
} from '@angular/router';
import { Token, LoginType, AuthLoginState } from '../core/auth/auth.models';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { loadTopic } from '../domain/topic/topic.actions';
import { loadTemplateCt } from '../domain/template-ct/template-ct.actions';
import { replaceAll, getQueryParametersFromURL } from '../shared/util/util';
import { Observable, merge, of, tap, take } from 'rxjs';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '../../environments/environment.service';
import { LogService } from '../core/log/log.service';
import { LocalStorageService } from '../core/local-storage/local-storage.service';
import { META_REDUCERS, Store, provideState, provideStore } from '@ngrx/store';
// import { CoreModule } from './app/core/core.module';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
  Meta,
  Title
} from '@angular/platform-browser';
import { APP_ROUTES } from './app.routes';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
  provideRouterStore
} from '@ngrx/router-store';
import { reducers } from '../core/core.state';
import { provideEffects } from '@ngrx/effects';
import { UserEffects } from '../domain/user/user.effects';
import { WidgetEffects } from '../domain/widget/widget.effects';
import { DialogEffects } from '../shared/dialog/dn-dialog.effects';
import { CookieConsentEffects } from '../core/cookie-consent/cookie-consent.effects';
import { AuthEffects } from '../core/auth/auth.effects';
import { ClickEffects } from '../core/clicks/clicks.effects';
import { ErrorEffects } from '../core/error-handler/error.effects';
import { RoutesEffects } from '../core/router/routes.effects';
import { SessionEffects } from '../core/session/session.effects';
import { TopicEffects } from '../domain/topic/topic.effects';
import { CookieService } from '../core/cookie-consent/services/cookie/cookie.service';
import {
  //	HTTP_INTERCEPTORS,
  HttpClient,
  //	HttpClientModule,
  provideHttpClient, withFetch
  //	withInterceptorsFromDi,
  //    withRequestsMadeViaParent
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DottnetErrorHandler } from '../core/error-handler/error-handler';
import { MetaService } from '../core/meta/meta.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CustomSerializer } from '../core/router/custom-serializer';
import { metaReducerFactory } from '../core/meta-reducers/debug.reducer';
// import { CookieInterceptor } from './app/core/interceptors/cookie.interceptor';
import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools';
import { CityEffects } from '../domain/city/city.effects';
import { CategoryEffects } from '../domain/category/category.effects';
import { SpecialtyEffects } from '../domain/specialty/specialty.effects';
import { ProvinceEffects } from '../domain/province/province.effects';
import { UserFormEffects } from '../shared/user-form/user-form.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationEffects } from '../core/notifications/notification.effects';
import { initDottnet } from '../core/init/init-dottnet';
import { CallService } from '../core/call-service/browser/call.service';
import { APIKEY } from '../../../src-server/tokens';
import { ProfessionEffects } from '../domain/profession/profession.effects';
import { InitEffects } from '../core/init/init.effects';
import { PollEffects } from '../domain/poll-smart/poll-smart.effects';
import { consentsFeature } from '../domain/external-consent/external-consent.features';
import { ExternalConsentsEffects } from '../domain/external-consent/external-consent.effects';
import { AgenasDisciplineEffects } from '../domain/agenas-discipline/agenas-discipline.effects';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
};

// making hammer config (3)
@Injectable({ providedIn: 'root' })
class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: true }
  };
}


export function errorHandlerFactory(injector: Injector) {
  return new DottnetErrorHandler(injector);
}

export function metaServiceFactory(
  title: Title,
  meta: Meta,
  environmentService: EnvironmentService,
  translateService: TranslateService,
  document: Document
) {
  return new MetaService(title, meta, environmentService, translateService, document);
}


export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.i18nPrefix}/assets/i18n/`, '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    //provideClientHydration(),
    provideHttpClient(withFetch()), // , withInterceptorsFromDi()),
    provideRouter(
      APP_ROUTES,
      withPreloading(NoPreloading),
      withInMemoryScrolling(scrollConfig),
      withEnabledBlockingInitialNavigation()
      // ?	scrollPositionRestoration: 'enabled',
    ),
    provideStore(reducers, {
      // Added to prevent error when opening generic matDialog with ComponentType<ANY> type
      runtimeChecks: {
        strictActionImmutability: false
      }
    }),
    provideState(consentsFeature),
    provideEffects(
      AuthEffects,
      UserEffects,
      WidgetEffects,
      //			GoogleAnalyticsEffects,
      ClickEffects,
      SessionEffects,
      DialogEffects,
      RoutesEffects,
      ErrorEffects,
      CookieConsentEffects,
      TopicEffects,
      CityEffects,
      CategoryEffects,
      SpecialtyEffects,
      ProfessionEffects,
      AgenasDisciplineEffects,
      ProvinceEffects,
      UserFormEffects,
      NotificationEffects,
      InitEffects,
      PollEffects,
      ExternalConsentsEffects
    ),
    provideRouterStore(),
    provideStoreDevtools({
      name: 'Dottnet',
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideAnimations(),
    importProvidersFrom(
      MatDialogModule,
      MatSidenavModule,
      ReactiveFormsModule,
      MatSnackBarModule,
      HammerModule,
      StoreRouterConnectingModule.forRoot(),
      /* environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                name: 'Dottnet'
            }),
            */
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      CookieService


      // app
      // AppRoutingModule
    ),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    provideAppInitializer(() => {
      const platformId = inject(PLATFORM_ID);
      const store: Store = inject(Store);
      const localStorageService: LocalStorageService = inject(LocalStorageService);
      const logService: LogService = inject(LogService);
      const envService: EnvironmentService = inject(EnvironmentService);
      const translateService: TranslateService = inject(TranslateService);
      return initDottnet(platformId, store, localStorageService, logService, envService, translateService);
    }),

    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: ErrorHandler, deps: [Injector], useFactory: errorHandlerFactory },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: META_REDUCERS,
      deps: [LogService],
      useFactory: metaReducerFactory,
      multi: true
    },
    // { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
    {
      provide: MetaService,
      useFactory: metaServiceFactory,
      deps: [Title, Meta, EnvironmentService, TranslateService, DOCUMENT]
    }
  ]
};
